import { graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Header from "./header/header"
import Footer from "./footer/footer"
import style from "./layout.module.css"
import "../../__generated__/gatsby-types"

const Layout = ({ children }) => {
  const data = useStaticQuery<GatsbyTypes.SiteSettingsQuery>(graphql`
    query SiteSettings {
      siteSettings: sanitySiteSettings {
        title
        openGraph {
          title
          description
          image {
            asset {
              fixed {
                src
              }
            }
          }
        }
        phone
        logo {
          asset {
            url
          }
        }
        instagram
        facebook
        kvk
        menuMain {
          menuItems {
            _key
            title
            page {
              slug {
                current
              }
            }
            subMenuItems {
              _key
              title
              page {
                slug {
                  current
                }
              }
            }
          }
        }
        menuFooter {
          menuItems {
            _key
            title
            page {
              slug {
                current
              }
            }
          }
        }
      }
    }
  `)
  return (
    <>
      <Header {... data} />
      <div id="contentContainer" className={style.main}>
        <main>{children}</main>
      </div>
      <Footer {... data} />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
