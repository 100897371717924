import { Link } from "gatsby";
import React from "react";
import style from "./footer.module.css"
const iconFacebook = require('./../../../assets/facebook.svg');
const iconInstagram = require('./../../../assets/instagram.svg');

const Footer = (data: GatsbyTypes.SiteSettingsQuery) => {
  const settings = data.siteSettings
  const menu = settings.menuFooter
  const facebook = data.siteSettings.facebook
  const instagram = data.siteSettings.instagram
  return (
    <footer className={style.main} id="footer">
      {settings.kvk &&
        <span className={style.item}>Kvk nummer {settings.kvk}</span>
      }
      {menu && menu.menuItems && menu.menuItems.map((menuItem) => {
        return (
          <span className={style.linkWrapper} key={menuItem._key}>
            <Link className={style.link} to={`/${menuItem.page.slug.current}/`} activeClassName={style.linkActive}>{menuItem.title}</Link>
          </span>
        )}
      )}
      <span className={style.linkWrapper}>
        <Link className={style.link} to="/nieuws/" activeClassName={style.linkActive}>Nieuws</Link>
      </span>
      <span className={style.linkWrapper}>
        <a className={style.linkExternal} href="https://esteraluitvaartzorg.condoleer.site/" target="_blank" rel="external noopener noreferrer rnofollow">Condoleance</a>
      </span>
      <span className={style.socialList}>
        {facebook && <a className={style.socialLink} href={facebook} target="_blank" rel="external noopener noreferrer rnofollow"><img src={iconFacebook} alt="Facebook" width="30" height="30" /></a>}
        {instagram && <a className={style.socialLink} href={instagram} target="_blank" rel="external noopener noreferrer nofollow"><img src={iconInstagram} alt="Instagram" width="30" height="30" /></a>}
      </span>
    </footer>
  );
}

export default Footer