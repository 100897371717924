import { Link, useStaticQuery, graphql } from "gatsby";
import React, { useState } from "react";
import { parsePhoneNumber, ParseError } from 'libphonenumber-js'
import style from "./header.module.css"
const phone = require('./../../../assets/telefoon.svg')

const Header = (data: GatsbyTypes.SiteSettingsQuery) => {
  const [state, setState] = useState({
    menu: false,
  });

  // toggle dropdown visibility
  const toggleVisibility = () => {
    setState({
      ...state,
      menu: !state.menu,
    });
  };

  const settings = data.siteSettings
  const menu = settings.menuMain
  const phoneNumber = (settings.phone && settings.phone.length > 9 ) && parsePhoneNumber(settings.phone, 'NL')
  return (
    <header className={style.main} data-menu={state.menu}>
      <div className={style.logo}>
        <Link to="/" className={style.logo}>
          {settings.logo && settings.logo.asset ?
            <img src={settings.logo.asset.url} className={style.logo} alt={settings.title} width="180" />
            :
            <div>Logo placeholder</div>
          }
        </Link>
      </div>
      <nav className={style.menuContainer} aria-hidden={!state.menu} data-active={state.menu}>
        <ul className={style.menu}>
          {menu && menu.menuItems && menu.menuItems.map((menuItem) => {
            const slug = menuItem.page.slug.current === "home" ? "/" : `/${menuItem.page.slug.current}/`
            const submenu = menuItem.subMenuItems && menuItem.subMenuItems.length ? menuItem.subMenuItems : false;
            return (
              <li className={style.menuItem} key={menuItem._key}>
                <Link className={style.link} to={slug} activeClassName={style.linkActive} partiallyActive={slug === "/" ? false : true}>{menuItem.title}</Link>
                {submenu &&
                  <ul className={style.subMenu}>
                    {submenu.map((submenuItem) => {
                      return (
                        <li className={style.submenuItem} key={submenuItem._key}>
                          <Link className={style.link} to={submenuItem.page.slug && `/${submenuItem.page.slug.current}/`} activeClassName={style.linkActive}>{submenuItem.title && submenuItem.title}</Link>
                        </li>
                      )}
                    )}
                  </ul>
                }
              </li>
            )}
          )}
        </ul>
      </nav>
      {settings.phone && phoneNumber &&
        <div className={style.phoneContainer}>
          <a href={phoneNumber.getURI()} className={style.phone}><span>{settings.phone}</span><img className={style.phoneIcon} src={phone} width="70" height="70" alt="Telefoon" /></a>
        </div>
      }
      <div className={style.menuOpen} onClick={toggleVisibility}>
        <div className={style.circle}>Menu</div>
      </div>
      <div className={style.menuClose} onClick={toggleVisibility}>
        <div className={style.circleAlt}>Sluit</div>
      </div>
    </header>
  );
}

export default Header

